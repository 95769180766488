import axios from 'axios';

export async function saveToFile(fileName, message, language) {
    if (message.length > 200) {
        if(language === 'es-MX'){ language = 'es-ES'; }
        return axios.post('/api/savetofile',{
            filename: fileName+'_'+language,
            mensaje: message
        });
    }
    return false;
}

export async function saveToFileShort(fileName, message, language) {
    if(language === 'es-MX'){ language = 'es-ES'; }
    return axios.post('/api/savetofile',{
        filename: fileName+'_'+language,
        mensaje: message
    });
}
