import React, {useRef,useEffect} from 'react';

const AlwaysScrollToBottom = () => {

    const elementRef = useRef();
    useEffect(() => elementRef.current.scrollIntoView({ behavior: 'smooth' }));
    return <div ref={elementRef} />;
};

export default class MessageList extends React.Component {

    render(){
        return <ul className={"messageListContainer messageListContainer--" + this.props.scroll}>
            {this.props.data.map((number) =>
                <li key={number.key} className="previous__messageItem">
                    <div className="previous__messageItemText">{number.message}</div>
                    <span className="messageItemTime">{number.time}</span>
                </li>
            )}
            <AlwaysScrollToBottom />
        </ul>;
    }
}