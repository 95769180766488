import React, { Component } from 'react';

class NoMatch extends Component {
    render() {
        return (
            <div className="home home-forbidden">
                <p className="titleText">WATS</p>
                <p className="titleText titleText--big titleText--center">OOPS!<br/><span>THAT PAGE<br/>COULDN'T BE FOUND</span></p>
            </div>
        );
    }
}

export default NoMatch;