// App.js
import React, { Component } from 'react';
import { BrowserRouter as Router, Routes, Route, useParams } from 'react-router-dom';
import Home from './components/Home';
import Classroom from './components/Classroom';
import NoMatch from './components/NoMatch';

class App extends Component {

   constructor(props) {
      super(props);
      this.state = {
         ipinfo: '37.143.122.43',
         ready: false
      }
   }

   componentDidMount() {
      /*fetch('https://geolocation-db.com/json/0f761a30-fe14-11e9-b59f-e53803842572')
         .then(res => res.json())
         .then((data) => {
            this.setState({ ipinfo: data.IPv4 });
            this.setState({ ready: true });
            console.log(data);
         })
         .catch(console.log);
         */

      const apiKey = 'a6376467d66d43fd8f24f140546c52ee';  // Reemplaza 'YOUR_API_KEY' con tu token

      fetch(`https://api.ipgeolocation.io/ipgeo?apiKey=${apiKey}`)
         .then(res => res.json())
         .then((data) => {
            this.setState({ ipinfo: data.ip });
            this.setState({ ready: true });
            //console.log(data);
         })
         .catch(console.log);
   }

   render() {
      if (this.state.ipinfo === '2.136.94.157' || this.state.ipinfo === '37.143.122.43') {
         const Wrapper = (props) => {
            const params = useParams();
            return <Classroom {...{ ...props, match: { params } }} />
         }

         return (
            <Router>
               <div>
                  {this.state.ready
                     ? <Routes>
                        <Route exact path='/' element={<Home />} />
                        <Route path='/classroom/:id' element={<Wrapper />} />
                        <Route path='*' element={<NoMatch />} />
                     </Routes>
                     : <div className="home home-forbidden">
                        <p className="titleText">LOADING...</p>
                     </div>
                  }
               </div>
            </Router>
         );
      } else {
         return (
            <div className="home home-forbidden">
               <p className="titleText">WATS</p>
               <p className="titleText titleText--big">FORBIDDEN ACCESS</p>
            </div>
         );
      }
   }
}

export default App;