import React, { Component } from 'react';
import { Link } from "react-router-dom";

class Home extends Component {
   render() {
      return (
         <div className="home">
            <div className="header__container">
               <p className="titleText">WATS</p>
            </div>
            <nav className="class-menu">
               <ul className="class-menu__list">
                  <li><Link to={'/classroom/d1'} onClick={this.forceUpdate} className="class-menu__link"> D<sup>1</sup> </Link></li>
                  <li><Link to={'/classroom/d2'} onClick={this.forceUpdate} className="class-menu__link"> D<sup>2</sup> </Link></li>
                  <li><Link to={'/classroom/d3'} onClick={this.forceUpdate} className="class-menu__link"> D<sup>3</sup>  </Link></li>
                  <li><Link to={'/classroom/t1'} onClick={this.forceUpdate} className="class-menu__link"> T<sup>1</sup>  </Link></li>
                  <li><Link to={'/classroom/t2'} onClick={this.forceUpdate} className="class-menu__link"> T<sup>2</sup>  </Link></li>
                  <li><Link to={'/classroom/k4'} onClick={this.forceUpdate} className="class-menu__link"> K<sup>4</sup>  </Link></li>
                  <li><Link to={'/classroom/aa'} onClick={this.forceUpdate} className="class-menu__link"> AA </Link></li>
                  <li><Link to={'/classroom/ch1'} onClick={this.forceUpdate} className="class-menu__link"> CH<sup>1</sup> </Link></li>
                  <li><Link to={'/classroom/ch2'} onClick={this.forceUpdate} className="class-menu__link"> CH<sup>2</sup> </Link></li>
               </ul>
            </nav>
         </div>
      );
   }
}

export default Home;